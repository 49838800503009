@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #1ba098;
}

a:hover {
  text-decoration: none;
  color: #6bbbff;
}

.app__container {
  padding-top: 30px;
}

.app__row {
  height: 95vh;
}

.app__main-content {
  border-radius: 20px;
  padding: 25px 15px;
  background-color: #051622;
  box-shadow: 0 0 10px #051622;
}

/* Sidebar */
.sidebar {
  height: 100%;
  border-radius: 20px;
  padding: 25px 15px;
  text-align: center;
  color: #dbdddf;
  background-color: #051622;
  box-shadow: 0 0 10px #051622;
  font-family: "Righteous";
}

.sidebar__avatar {
  height: 140px;
  width: 130px;
  border-radius: 8px;
  border-color: yellow;
}

.sidebar__container {
  margin: -20 -20;
}

.sidebar__icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #dbdddf;
}

.sidebar__name {
  margin: 20px 0;
  font-size: 1.7rem;
}

.sidebar__item {
  margin-bottom: 6px;
  padding-top: 6px;
  height: 40px;
  font-size: 1.05rem;
  border-radius: 30px;
}

.sidebar__contact {
  border-radius: 30px;
  margin: 15px 25px 0px;
  cursor: pointer;
}

.sidebar__title {
  margin-bottom: 25px;
}

.sidebar__github:hover {
  background-color: #c2e9fb;
  color: #000;
  transition: all 1s linear;
}

@media (max-width: 991px) {
  .app__main-content {
    margin: 30px 0;
  }
}

/* Navbar */
.navbar__items {
  display: flex;
}

.navbar__active {
  font-size: 2rem;
  font-weight: 700;
  color: #1ba098;
  border-bottom: 4px solid #1ba098;
}

.navbar__item {
  margin-right: 20px;
  text-decoration: none;
  color: #1ba098;
  font-size: 1.3rem;
  cursor: pointer;
}

.navbar__item:hover {
  color: #6bbbff;
}

/* Bar */
.bar {
  width: 90%;
  background-color: #f1f2f3;
  border-radius: 20px;
  margin-bottom: 12px;
}

.bar__icon {
  width: 15px;
  height: 15px;
}

.bar__wrapper {
  background: linear-gradient(109.5deg, #c2e9fb 11.2%, #1d79ff 91.1%);
  border-radius: 20px;
  padding: 4px 14px;
  color: #000;
  font-size: 0.9rem;
}

/* About */
.about {
  padding: 20px;
  margin: -35px;
}

.about__intro {
  font-size: 1.1rem;
  padding: 10px 20px;
  margin: 10px 0;
  color: #dbdddf;
}

.about__container {
  background-color: #051622;
  padding: 10px 15px;
  border-radius: 0 0 8px 8px;
}

.about__heading {
  font-size: 25px;
  font-weight: 700;
  color: #dbdddf;
  margin-left: 10px;
}

/* About-card */
.about-card {
  background-color: #ebeef0;
  margin: 10px 0px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
}

.about-card__icon {
  width: 40px;
  height: 40px;
}

.about-card__body {
  margin-left: 10px;
}

.about_card__content {
  font-size: 0.9rem;
  color: #6e7e91;
}

/* Skills */
.skills {
  min-height: 70vh;
  padding-top: 30px;
}

.skills__navbar {
  display: flex;
  margin-bottom: 15px;
  color: #deb992;
}

.skills__navbar > div {
  margin: 0 5px;
  font-size: 1rem;
  cursor: pointer;
}

.skills__navbar-active {
  color: #1ba098;
  border-bottom: 2px solid #1ba098;
}

.skillCard__wrapper {
  margin: 10px 10px;
}

.skillCard__image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  border: 1px solid yellow;
  background-color: #dbdddf;
  transition: transform 0.3s linear;
}

/* Skills card */

.skillCard__image:hover {
  transform: scale(1.05);
}

.skillCard__icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.skillCard__title {
  margin-top: 5px;
  font: size 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Projects */
.projects {
  height: 80vh;
  padding-top: 30px;
  overflow-y: auto;
}

.projectCard__wrapper {
  margin: 10px 0;
  width: 100%;
  height: 100%;
  min-height: 160px;
}

.projectCard__image {
  width: 100%;
  height: 80%;
  border-radius: 15px;
  border: 1px solid rgb(223, 223, 205);
  transition: transform 0.5s linear;
}

.projectCard__icon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #dbdddf;
}

.projectCard__title {
  margin-top: 12px;
  font: size 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projectCard__image:hover {
  transform: scale(1.05);
}
